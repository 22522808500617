import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import VideoShowcase from '../../components/VideoShowcase/VideoShowcase';
import styles from './ImageDetail.module.css';
import { getImgInfo, fetchRecommendImages, fetchRecommendVideos } from '../../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { UserContext } from '../../context/UserContext';

interface ImgInfo {
    title: string;
    description: string;
    imgList: string[];  // 图片列表
    indexImg: string;
    imgCount: number;
    viewCount: number;
}

interface RecommendedImage {
    title: string;
    indexImg: string;
    id: number;
    isVip: boolean;
    vipLevel: number;
    qualityMark: boolean; // 精品标记
}

interface RecommendedVideo {
    src: string;
    poster: string;
    id: number;
    title: string;
    indexImg: string;
    isVip: boolean;
    vipLevel: number;
    qualityMark: boolean; // 精品标记
}

const ImageDetail: React.FC = () => {
    const { id } = useParams();
    const [imgInfo, setImgInfo] = useState<ImgInfo | null>(null); // 图片详情状态
    const [recommendedImages, setRecommendedImages] = useState<RecommendedImage[]>([]);
    const [recommendedVideos, setRecommendedVideos] = useState<RecommendedVideo[]>([]);
    const [loadingImages, setLoadingImages] = useState<boolean>(true);
    const [loadingVideos, setLoadingVideos] = useState<boolean>(true);
    const [retryCount, setRetryCount] = useState(0);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [message, setMessage] = useState<string | null>(null); // 后端返回的信息提示
    const userContext = useContext(UserContext);
    const { user } = userContext;
    const maxRetryAttempts = 3;
    const navigate = useNavigate();

    const handleUpgradeClick = () => {
        navigate('/upgradeVip');
    };

    const fetchRecommendations = async () => {
        try {
            setLoadingImages(true);
            const recommendResponse = await fetchRecommendImages(id!);
            if (recommendResponse && recommendResponse.isSuccess) {
                const recommendedData = recommendResponse.data.map((item: any) => ({
                    title: item.title,
                    indexImg: item.indexImg,
                    id: item.id,
                    isVip: item.isVip,
                    vipLevel: item.vipLevel,
                    qualityMark: item.qualityMark,
                }));
                setRecommendedImages(recommendedData);
            }
            setLoadingImages(false);

            setLoadingVideos(true);
            const recommendVideoResponse = await fetchRecommendVideos("");
            if (recommendVideoResponse && recommendVideoResponse.isSuccess) {
                const recommendedVideos = recommendVideoResponse.data.map((item: any) => ({
                    src: item.src,
                    poster: item.poster,
                    id: item.id,
                    title: item.title,
                    indexImg: item.indexImg,
                    isVip: item.isVip,
                    vipLevel: item.vipLevel,
                    qualityMark: item.qualityMark,
                }));
                setRecommendedVideos(recommendedVideos);
            }
            setLoadingVideos(false);
        } catch (error) {
            console.error("Failed to fetch recommendations:", error);
            // 自动重试提示
            if (retryCount < maxRetryAttempts) {
                setRetryCount(retryCount + 1);
                fetchRecommendations();
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getImgInfo(id!);
                if (response && response.isSuccess) {
                    const data = response.data;
                    setImgInfo({
                        title: data.title,
                        description: data.description,
                        imgList: JSON.parse(data.imgList),
                        indexImg: data.indexImg,
                        imgCount: data.imgCount,
                        viewCount: data.viewCount,
                    });
                    setMessage(response.message);
                }
                fetchRecommendations();
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        fetchData();
    }, [id]);

    // 监听滚动事件，控制回到顶部按钮显示/隐藏
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    if (loadingImages || loadingVideos) {
        return (
            <>
                <Skeleton height={400} /> {/* Skeleton loading while data is being fetched */}
            </>
        );
    }

    if (!imgInfo) {
        return <p>No image details found</p>;
    }

    return (
        <>
            <Helmet>
                <title>{imgInfo.title} - www.GirL8.top</title>
            </Helmet>

            <section className={styles.image_detail_section}>
                <div className={styles.image_detail_container}>
                    {/* 主图片展示区域 */}
                    <div className={styles.main_image_gallery}>
                        <h2 className={styles.image_title}>{imgInfo.title}</h2>

                        {/* 提示信息显示 */}
                        {message && (
                            <div className={styles.permission_message}>
                                <p>{message}</p>
                                {message.includes("Register") ? (
                                    <button className={styles.upgrade_button} onClick={() => navigate('/register')}>
                                        Free Registration
                                    </button>
                                ) : (
                                    <button className={styles.upgrade_button} onClick={handleUpgradeClick}>
                                        Upgrade to VIP
                                    </button>
                                )}
                            </div>
                        )}

                        <div className={styles.main_image}>
                            {/* 根据用户登录状态显示图片数量 */}
                            {imgInfo.imgList.slice(0, user ? imgInfo.imgList.length : 3).map((src, index) => (
                                <img
                                    key={index}
                                    src={`${src}`}
                                    alt={`${imgInfo.title}${index + 1}`}
                                    className={styles.gallery_image}
                                    loading="lazy" // 懒加载图片
                                />
                            ))}
                        </div>
                        <p className={styles.image_description}>{imgInfo.description}</p>
                        <p>Views: {imgInfo.viewCount} | Number of images: {imgInfo.imgCount}</p>
                    </div>

                    {/* 推荐图片展示 */}
                    <div className={styles.sidebar}>
                        <div className={styles.recommended_images}>
                            <h3>Related recommendations</h3>
                            {loadingImages ? (
                                <Skeleton count={4} height={150} />
                            ) : (
                                <div className={styles.related_images_grid}>
                                    {recommendedImages.map((image, index) => (
                                        <div key={index} className={styles.related_image}>
                                            {/* 精品和VIP标签 */}
                                            {image.qualityMark && <div className={styles.quality_mark}>Premium</div>}
                                            {image.isVip && <div className={styles.vip_mark}>VIP</div>}
                                            <a href={`/showImg/${image.id}`}>
                                                <img
                                                    src={`${image.indexImg}`}
                                                    alt={image.title}
                                                    loading="lazy"
                                                />
                                            </a>
                                            <a className={styles.image_titleLink} href={`/showImg/${image.id}`}>
                                                <p>{image.title}</p>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            {/* 推荐视频展示 */}
            <section className={styles.recommended_videos_section}>
                {loadingVideos ? (
                    <Skeleton count={4} height={200} />
                ) : (
                    <VideoShowcase title="Recommended videos" videos={recommendedVideos} typeid={0} />
                )}
            </section>

            {/* 回到顶部按钮 */}
            {showScrollToTop && (
                <button className={styles.scroll_to_top} onClick={scrollToTop}>
                    Back to top
                </button>
            )}
        </>
    );
};

export default ImageDetail;
