import React, { useEffect, useRef, useState, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './VideoShow.module.css';
import VideoShowcase from '../../components/VideoShowcase/VideoShowcase';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchRecommendImages, fetchRecommendVideos, getVideoInfo } from '../../services/api';
import { Helmet } from 'react-helmet';
import shaka from 'shaka-player/dist/shaka-player.compiled.js'; //     引入 ShakaPlayer

interface VideoInfo {
    title: string;
    description: string;
    imgList: string[];
    indexImg: string;
    imgCount: number;
    viewCount: number;
    isVip: boolean;
    playTime: number;
}

interface RecommendedImage {
    title: string;
    indexImg: string;
    id: number;
    isVip: boolean;
    vipLevel: number;
    qualityMark: boolean;
}

interface RecommendedVideo {
    src: string;
    poster: string;
    id: number;
    title: string;
    indexImg: string;
}

const VideoShow: React.FC = () => {
    const { id } = useParams();
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const playerRef = useRef<shaka.Player | null>(null);
    const [videoInfo, setVideoInfo] = useState<VideoInfo | null>(null);
    const [recommendedImages, setRecommendedImages] = useState<RecommendedImage[]>([]);
    const [recommendedVideos, setRecommendedVideos] = useState<RecommendedVideo[]>([]);
    const [loading, setLoading] = useState({ images: true, videos: true });
    const [error, setError] = useState({ images: false, videos: false });
    const [retryCount, setRetryCount] = useState(0);
    const [message, setMessage] = useState<string | null>(null);
    const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);
    const navigate = useNavigate();

    // 检查登录用户的VIP状态
    const checkUserVipStatus = () => {
        const user = localStorage.getItem('user');
        if (user) {
            const parsedUser = JSON.parse(user);
            return parsedUser.user.userGroupId > 1;
        }
        return false;
    };

    // 视频播放结束时显示升级提示（如果不是VIP）
    const handleVideoEnded = () => {
        if (!checkUserVipStatus()) {
            setShowUpgradePrompt(true);
        }
    };

    const handleUpgradeClick = () => {
        navigate('/upgradeVip');
    };

    // 加载推荐内容的重试功能
    const handleRetry = () => {
        if (retryCount < 3) {
            setRetryCount(retryCount + 1);
            fetchRecommendations();
        }
    };

    // 获取推荐内容
    const fetchRecommendations = useCallback(async () => {
        try {
            setLoading((prev) => ({ ...prev, images: true, videos: true }));
            setError((prev) => ({ ...prev, images: false, videos: false }));

            const recommendResponse = await fetchRecommendImages('0');
            if (recommendResponse && recommendResponse.isSuccess) {
                const recommendedData = recommendResponse.data.map((item: any) => ({
                    title: item.title,
                    indexImg: item.indexImg,
                    id: item.id,
                    isVip: item.isVip,
                    vipLevel: item.vipLevel,
                    qualityMark: item.qualityMark,
                }));
                setRecommendedImages(recommendedData);
            } else {
                setError((prev) => ({ ...prev, images: true }));
            }

            const recommendVideoResponse = await fetchRecommendVideos('');
            if (recommendVideoResponse && recommendVideoResponse.isSuccess) {
                const recommendedVideos = recommendVideoResponse.data.map((item: any) => ({
                    src: item.src,
                    poster: item.poster,
                    id: item.id,
                    title: item.title,
                    indexImg: item.indexImg,
                    isVip: item.isVip,
                    vipLevel: item.vipLevel,
                    qualityMark: item.qualityMark,
                }));
                setRecommendedVideos(recommendedVideos);
            } else {
                setError((prev) => ({ ...prev, videos: true }));
            }

            setLoading({ images: false, videos: false });
        } catch (error) {
            setError({ images: true, videos: true });
            console.error('加载推荐内容失败：', error);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getVideoInfo(id!);
                if (response && response.isSuccess) {
                    const data = response.data;
                    setVideoInfo({
                        title: data.title,
                        description: data.description,
                        imgList: data.imgList,
                        indexImg: data.indexImg,
                        imgCount: data.imgCount,
                        viewCount: data.viewCount,
                        isVip: data.isVip,
                        playTime: data.playTime,
                    });
                    setMessage(response.message);
                }
                fetchRecommendations();
            } catch (error) {
                console.error('获取视频详情失败：', error);
            }
        };
        fetchData();
    }, [id, fetchRecommendations]);

    // 初始化 Shaka Player 并配置 HLS 播放
    useEffect(() => {
        const videoElement = videoRef.current;

        if (videoElement) {
            // 初始化 Shaka Player
            const player = new shaka.Player(videoElement);
            playerRef.current = player;

            // 配置 HLS 支持和 polyfill
            shaka.polyfill.installAll();

            // 错误处理
            player.addEventListener('error', onErrorEvent);

            // 加载 HLS 播放列表
            const videoSrc = `/api/VideoInfo/M3U8?id=${id}`;
            player.load(videoSrc)
                .then(() => {
                    console.log('The video has been loaded successfully!');
                    videoElement.play(); // 播放视频
                })
                .catch(onError);

            videoElement.addEventListener('ended', handleVideoEnded);

            // 卸载时销毁播放器
            return () => {
                if (playerRef.current) {
                    playerRef.current.destroy();
                }
                videoElement.removeEventListener('ended', handleVideoEnded);
            };
        }
    }, [id]);

    const onErrorEvent = (event: any) => {
        onError(event.detail);
    };

    const onError = (error: any) => {
        console.error('Shaka Player Error:', error);
    };

    return (
        <>
            <Helmet>
                <title>{videoInfo?.title} - www.girl8.top</title>
            </Helmet>
            <section className={styles.image_detail_section}>
                <div className={styles.image_detail_container}>
                    <div className={styles.main_image_gallery}>
                        <h2 className={styles.image_title}>{videoInfo?.title}</h2>

                        {message && (
                            <div className={styles.permission_message}>
                                <p>{message}</p>
                            </div>
                        )}

                        <div className={styles.video_container}>
                            {/* 视频播放区，使用 Shaka Player */}
                            <video
                                ref={videoRef}
                                className={styles.main_video}
                                controls
                                poster={videoInfo?.indexImg || '/loadingVideo.png'}
                            />
                            {showUpgradePrompt && (
                                <div className={styles.upgrade_prompt}>
                                    <p>Your trial has ended. Upgrade to VIP to continue watching the full video.</p>
                                    <button className={styles.upgrade_button} onClick={handleUpgradeClick}>
                                        Upgrade to VIP
                                    </button>
                                </div>
                            )}
                        </div>
                        <p className={styles.image_description}>{videoInfo?.description}</p>

                        <section className={styles.recommended_videos_section}>
                            {loading.videos ? (
                                <Skeleton count={1} height={200} />
                            ) : error.videos ? (
                                <div>
                                    <p>Failed to load recommended videos</p>
                                    {retryCount < 3 ? (
                                        <button onClick={handleRetry}>Retry loading recommended videos</button>
                                    ) : (
                                        <p>Please try again later.</p>
                                    )}
                                </div>
                            ) : (
                                <VideoShowcase title="Recommended videos" videos={recommendedVideos} typeid={0} />
                            )}
                        </section>
                    </div>

                    <div className={styles.sidebar}>
                        <div className={styles.recommended_images}>
                            <h3>Recommended images</h3>
                            {loading.images ? (
                                <Skeleton count={4} height={150} />
                            ) : error.images ? (
                                <div>
                                    <p>Failed to load recommended images</p>
                                    {retryCount < 3 ? (
                                        <button onClick={handleRetry}>Retry loading recommended images</button>
                                    ) : (
                                        <p>Please try again later.</p>
                                    )}
                                </div>
                            ) : (
                                <div className={styles.related_images_grid}>
                                    {recommendedImages.map((image, index) => (
                                        <div key={index} className={styles.related_image}>
                                            <a href={`/showImg/${image.id}`}>
                                                {image.qualityMark && <div className={styles.quality_mark}>精品</div>}
                                                {image.isVip && <div className={styles.vip_mark}>VIP</div>}
                                                <img src={image.indexImg} alt={image.title} />
                                            </a>
                                            <p>{image.title}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default VideoShow;
